import React from 'react';

import StyledIntro from './StyledIntro';

function Intro(props) {
    return (
        <StyledIntro>
            <h1>{props.title}</h1>
            <div>
                <p>Držíme se jednoduchých principů.</p>
                <p>Hledáme dlouhodobě udržitelnou cestu.</p>
                <p>Zvyšujeme kvalitu svého života.</p>
            </div>
        </StyledIntro>
    );
};

export default Intro;
