import React from 'react';

import DialogContext from './DialogContext';

function DialogContextProvider(props) {
    const [gdprDialogShow, gdprDialogSetShow] = React.useState(false);
    const [ofDialogShow, ofDialogSetShow] = React.useState(false);
    const [ofrDialogShow, ofrDialogSetShow] = React.useState(false);
    const [ofs, ofsSet] = React.useState(0);
    const [ofr, ofrSet] = React.useState(null);

    return (
        <DialogContext.Provider value={{
            gdprDialogShow,
            gdprDialogSetShow,
            ofDialogShow,
            ofDialogSetShow,
            ofrDialogShow,
            ofrDialogSetShow,
            ofs,
            ofsSet,
            ofr,
            ofrSet,
        }}>
            {props.children}
        </DialogContext.Provider>
    );
};

export default DialogContextProvider;
