import React from 'react';
import { Navbar } from 'react-bootstrap';

const Logo = (props) => {
    return (
        <Navbar.Brand href={props.href} className={props.className}>
            <img src={props.icon} className="d-inline-block align-top mr-2" alt={props.iconAlt} />
            <span>{props.title}</span>
        </Navbar.Brand>
    );
};

export default Logo;
