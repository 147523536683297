import React from 'react';
import { Container, Col, Row, Image } from "react-bootstrap";

import StyledAboutSection from './StyledAboutSection';

function AboutSection(props) {
    return (
        <StyledAboutSection id="about">
            <Container>
                <Row>
                    <Col lg={6} className="mx-auto">
                        <Image src={props.image} fluid />
                        <h2>Jan Klečka</h2>
                        <em>fitness trenér a závodník v&nbsp;naturální kulturistice</em>
                        <p>Jmenuji se Honza, jsem certifikovaný trenér a závodník v&nbsp;naturální kulturistice. Mou prací je ukázat vám, jakým způsobem můžete co nejefektivněji dosáhnout svých cílů, ať už chcete hubnout, přibrat, zlepšit kondici či se jednoduše jen začít hýbat.</p>
                    </Col>
                </Row>
            </Container>
        </StyledAboutSection>
    );
};

export default AboutSection;
