import styled from 'styled-components';

import { Col } from "react-bootstrap";

const StyledContact = styled(Col)`
    color: ${props => props.theme.colors.dark};
    
    i {
        font-size: 2rem;
        line-height: 3rem;
        color: ${props => props.theme.colors.dark2};
    }
    
    hr {
        width: 5rem;
        border-width: 0.25rem;
        border-color: ${props => props.theme.colors.accent};
    }
    
    .card {
        border: none;
        border-bottom: 1rem solid ${props => props.theme.colors.accent};
    }
`;

export default StyledContact;
