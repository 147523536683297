import styled from 'styled-components';

const StyledPhilosophySection = styled.section`
    padding-top: 3rem;
    padding-bottom: 2rem;
    
    color: ${props => props.theme.colors.dark2};
    
    h2 {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 4rem;
    }
    
    h2 strong {
        color: ${props => props.theme.colors.accent};
        font-weight: normal;
    }
    
    h3 {
        color: ${props => props.theme.colors.dark};
        font-size: 1.5rem;
    }
    
    p {
        text-align: justify;
        text-indent: 1rem;
    }
    
    ul {
      list-style: none;
    }
    
    li::before {
      content: '• ';
      color: ${props => props.theme.colors.accent};
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
`;

export default StyledPhilosophySection;
