//const API_BASE_URL = 'http://localhost:8001';
//const API_BASE_URL = 'http://simplefitness-api.lucavers.cz';
const API_BASE_URL = 'https://api.simple-fitness.cz';

const errors = {
    SEND_MAIL_LIMIT_EXCEEDED: 'Dosažen limit pro odesílání!',
};

export const mapApiError = (status) => {
    const errorKey = status.message;

    return errors[errorKey] || errorKey;
};


const client = {
    request: (path, method, body = null, config) => {
        let headers = config.headers || {};

        headers['Content-Type'] = 'application/json';

        return fetch(`${API_BASE_URL}${path}`, {
            method: method,
            body: body,
            headers: headers,
        }).then((response) => { return response.json(); });
    },
    get: (path, config) => {
        return client.request(path, 'GET', null, config);
    },
    post: (path, data, config) => {
        return client.request(path, 'POST', JSON.stringify(data), config);
    },
    put: (path, data, config) => {
        return client.request(path, 'PUT', JSON.stringify(data), config);
    },
    delete: (path, config) => {
        return client.request(path, 'DELETE', null, config);
    },
};

const Api = {
    post: (path, data, onSuccess, onFailure) => {
        client.post(`${path}`, data, {})
            .then((payload) => {
                if (payload.status.code >= 300) {
                    onFailure(mapApiError(payload.status));

                    return;
                }

                onSuccess(payload.data);
            })
            .catch((error) => {
                onFailure(error.message);
            })
        ;
    },
};

export default Api;
