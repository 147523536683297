import styled from 'styled-components';

const StyledReview = styled.div`
    .card {
        background-color: transparent;
        border: none;
    }
    
    .card-text {
        margin-bottom: 0.5rem;
        text-align: justify;
    }
    
    img {
        padding-top: 1em;
    }
`;

export default StyledReview;
