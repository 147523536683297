import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import ScrollSpyNav from 'react-scrollspy-nav';

import StyledLogo from './StyledLogo';
import StyledNavBar from './StyledNavBar';

function NavBar(props) {
    return (
        <ScrollSpyNav scrollTargetIds={props.sections.map(i => i.name)} offset={-56} activeNavClass="active" scrollDuration={500} headerBackground={true}>
            <StyledNavBar bg="light" variant="light" fixed="top" expand="lg">
                <Container>
                    <StyledLogo href="/" {...props.logo} />
                    <Navbar.Toggle aria-controls="main-navbar" />
                    <Navbar.Collapse id="main-navbar">
                        <Nav className="mr-auto" />
                        <Nav>
                            {props.sections.map(i => <Nav.Link key={i.name} href={`#${i.name}`}>{i.title}</Nav.Link>)}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </StyledNavBar>
        </ScrollSpyNav>
    );
};

export default NavBar;
