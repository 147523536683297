import { createGlobalStyle } from 'styled-components';

// this is because @import is not recommended yet in createGlobalStyle
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
//import 'nerdfonts-web/nf.css';

const GlobalStyle = createGlobalStyle`body {
    color: ${props => props.theme.colors.dark};
    background-color: ${props => props.theme.colors.light};
    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-family: ${props => props.theme.fonts.main.family};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
};`

export default GlobalStyle;
