import React from 'react';
import { Form, Row, Col } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';

import DialogContext from '../../DialogContext';

import StyledOrderForm from './StyledOrderForm';
import LoadingButton from '../content/LoadingButton';
import Link from '../content/Link';

import AppApi from '../../api/AppApi';

const getInitialValues = (serviceId) => ({ service: serviceId, firstName: '', lastName: '', phone: '', email: '', frequency: '', focus: '', message: '', terms: false });
const getValidationSchema = (extendedFormServiceId) =>
    Yup.object().shape({
        service: Yup.string(),
        firstName: Yup.string().required('Povinné'),
        lastName: Yup.string().required('Povinné'),
        phone: Yup.string().matches(/(00|\+)(420|421) ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}/, 'Musí odpovídat formátu +420 123 456 789 nebo 00420 123 456 789 nebo bez mezer').required('Povinné'),
        email: Yup.string().email('Musí být validní').required('Povinné'),
        frequency: Yup.string().when('service', {
            is: v => parseInt(v) === extendedFormServiceId,
            then: Yup.string().required('Nutné vybrat'),
        }),
        focus: Yup.string().when('service', {
            is: v => parseInt(v) === extendedFormServiceId,
            then: Yup.string().required('Nutné vybrat'),
        }),
        message: Yup.string(),
        terms: Yup.bool().required().oneOf([true], 'Povinné'),
    })
;

function OrderForm(props) {
    const dialogContext = React.useContext(DialogContext);

    const initialValues = getInitialValues(props.service.id);
    const validationSchema = getValidationSchema(props.extendedFormServiceId);

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={(values, { setSubmitting, resetForm }) => {
            const service = props.services.find(i => i.id === values.service);
            values.service = service.title;

            if (values.frequency === '') {
                values.frequency = null;
            }
            if (values.focus === '') {
                values.focus = null;
            }
            if (values.message === '') {
                values.message = null;
            }
            //console.log(values);

            AppApi.post('/tool/mail/send', values, data => {
                dialogContext.ofDialogSetShow(false);
                setSubmitting(false);
                resetForm();
                dialogContext.ofrSet(props.orderFormResults.success);
                dialogContext.ofrDialogSetShow(true);
            }, error => {
                console.log(error);
                dialogContext.ofDialogSetShow(false);
                setSubmitting(false);
                dialogContext.ofrSet(props.orderFormResults.failure);
                dialogContext.ofrDialogSetShow(true);
            });
        }}>
            {({ isSubmitting, handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <StyledOrderForm noValidate className="mx-auto">
                    <Row className="d-none">
                        <Col>
                            <Form.Group controlId="service">
                                <Form.Control
                                    as="select"
                                    name="service"
                                    value={values.service}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.service && errors.service}
                                    disabled
                                >
                                    {props.services.map(i => <option key={i.id} value={i.id} label={i.title} />)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.phone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="firstName">
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.firstName && errors.firstName}
                                    placeholder="Jméno"
                                />
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.firstName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="lastName">
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.lastName && errors.lastName}
                                    placeholder="Příjmení"
                                />
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.lastName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="phone">
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.phone && errors.phone}
                                    placeholder="Telefon"
                                />
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.phone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="email">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.email && errors.email}
                                    placeholder="Email"
                                />
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {props.service.id === 4 && (
                        <Row>
                            <Col>
                                <Form.Group controlId="frequency">
                                    <Form.Control
                                        as="select"
                                        name="frequency"
                                        value={values.frequency}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.frequency && errors.frequency}
                                    >
                                        <option value="" label="Chci trénovat" />
                                        <option value="2x týdně" label="2x týdně" />
                                        <option value="3x týdně" label="3x týdně" />
                                        <option value="4x týdně" label="4x týdně" />
                                        <option value="5x týdně" label="5x týdně" />
                                        <option value="6x týdně" label="6x týdně" />
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip={true}>{errors.frequency}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {props.service.id === 4 && (
                        <Row>
                            <Col>
                                <Form.Group controlId="focus">
                                    <Form.Control
                                        as="select"
                                        name="focus"
                                        value={values.focus}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.focus && errors.focus}
                                    >
                                        <option value="" label="Rád bych se zaměřil na" />
                                        <option value="svalový růst" label="svalový růst" />
                                        <option value="redukci tuku" label="redukci tuku" />
                                        <option value="zlepšení dynamiky" label="zlepšení dynamiky" />
                                        <option value="rozvoj maximální síly" label="rozvoj maximální síly" />
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid" tooltip={true}>{errors.focus}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Form.Group controlId="message">
                                <Form.Control
                                    as="textarea"
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.message && errors.message}
                                    rows={5}
                                    placeholder="Vzkaz"
                                />
                                <Form.Control.Feedback type="invalid" tooltip={true}>{errors.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Form.Group controlId="terms">
                                <Form.Check
                                    required
                                    name="terms"
                                    checked={values.terms}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.terms && !!errors.terms}
                                    feedback={errors.terms}
                                    feedbackTooltip={true}
                                    label={(
                                        <DialogContext.Consumer>
                                            {({ gdprDialogSetShow }) => (
                                                <React.Fragment>
                                                    Souhlasím se <Link label="zásadami zpracování osobních údajů" onClick={() => gdprDialogSetShow(true)} />
                                                </React.Fragment>
                                            )}
                                        </DialogContext.Consumer>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <LoadingButton label="Odeslat" labelLoading="Odesílám..." isLoading={isSubmitting} onClick={(event) => {
                                event.preventDefault();
                                event.target.blur();
                                handleSubmit();
                            }} />
                        </Col>
                    </Row>
                </StyledOrderForm>
            )}
        </Formik>
    );
};

export default OrderForm;
