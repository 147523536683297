import React from 'react';
import { Modal } from 'react-bootstrap';

import DialogContext from '../../DialogContext';

function OrderFormResultDialog(props) {
    return (
        <DialogContext.Consumer>
            {({ ofrDialogShow, ofrDialogSetShow, ofr }) => (
                <Modal show={ofrDialogShow} onHide={() => ofrDialogSetShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ofr && ofr.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{ofr && ofr.message}</p>
                    </Modal.Body>
                </Modal>
            )}
        </DialogContext.Consumer>
    );
};

export default OrderFormResultDialog;
