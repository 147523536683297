import React from 'react';

import { Image } from 'react-bootstrap';

import StyledPartner from './StyledPartner';

function Partner(props) {
    return (
        <StyledPartner variant="link" target="_blank" className="mx-2 mb-3" href={props.link} title={props.title}>
            <Image src={props.image} />
        </StyledPartner>
    );
};

export default Partner;
