import styled from 'styled-components';

import { Navbar } from 'react-bootstrap';

const StyledNavBar = styled(Navbar)`
    padding: 0 10px;
    background-color: ${props => props.theme.colors.light2} !important;
    
    && {
        .nav-link {
            color: #19150f;
            border-bottom: 0.25rem solid transparent;
        }
        
        .nav-link:hover {
            color: #a10b10;
        }
        
        .nav-link.active {
            color: #a10b10;
        }
        
        .nav-link strong {
            color: ${props => props.theme.colors.accent};
            font-weight: normal;
        }
        
        @media (min-width: 992px) {
            .nav-link {
                padding: 0.875rem;
            }
        }
    }
    
    @media (min-width: 992px) {
        .nav-link {
            border-bottom: 0.25rem solid transparent;
        }
    }
    
    @media (min-width: 992px) {
        .nav-link.active {
            border-bottom: 0.25rem solid #a10b10;
        }
    }
`;

export default StyledNavBar;
