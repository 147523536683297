import styled from 'styled-components';

const StyledAboutSection = styled.section`
    padding-top: 8rem;
    height: 1000px;
    
    text-align: center;

    color: ${props => props.theme.colors.light};
    background: ${props => props.theme.colors.dark} linear-gradient(
        to bottom,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 1)} 0%,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 0.9)} 75%,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 0.8)} 100%);

    h2 {
        margin-top: 2rem;
    }
    
    p {
        margin-top: 2rem;
        text-align: justify;
        text-indent: 1rem;
    }
`;

export default StyledAboutSection;
