import styled from 'styled-components';

import StyledButton from './StyledButton';

const StyledSocial = styled(StyledButton)`
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.3rem;
    border-radius: 100%;
    
    i {
        font-size: 2rem;
    }
`;

export default StyledSocial;
