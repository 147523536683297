import React from 'react';

import StyledSocial from './StyledSocial';

function Contact(props) {
    return (
        <StyledSocial target="_blank" className="mx-2" href={props.link} title={props.title}>
            <i className={`fa fa-${props.icon}`} />
        </StyledSocial>
    );
};

export default Contact;
