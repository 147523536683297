import React from 'react';

import StyledLink from './StyledLink';

function Link(props) {
    const onClick = (event) => {
        event.preventDefault();
        event.target.blur();

        props.onClick(event);
    };

    return (
        <StyledLink variant="link" as="a" {...props} onClick={onClick}>{props.label}</StyledLink>
    );
};

export default Link;
