import React from 'react';

import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledLink = styled(Button)`
    padding: 0;

    color: ${props => props.theme.colors.accent};
    
    &:hover, &:focus, &&&:active {
        color: ${props => props.theme.colors.accent2};
    }
    
    &:hover {
        cursor: pointer;
    }
`;

const sections = [
    { name: 'about', title: 'O mě' },
    { name: 'philosophy', title: (<React.Fragment><strong>Filosofie</strong> Simple Fitness</React.Fragment>) },
    { name: 'services', title: 'Služby' },
    { name: 'reviews', title: 'Recenze' },
    { name: 'contact', title: 'Kontakt' },
];

const logo = { icon: '/images/dumbbell.png', iconAlt: 'dumbbell', title: (<React.Fragment><strong>Simple</strong> Fitness</React.Fragment>) }

const imageIntro = '/images/jan_klecka_intro.jpg';
const imageOutro = '/images/jan_klecka_outro.jpg';
const imageAbout = '/images/jan_klecka.png';

const reviews = [
    { gender: 'man', name: 'David', subtitle: null, text: 'Honzu silne odporúčam, vďaka nemu už konečne chápem ako chudnúť aj bez cvičenia. Vysvetlil mi princípy a stále spolu komunikujeme ako postupovať. Chápem už aj svoje telo, čo potrebuje a ako funguje a som schopný to celé korigovať. Chudnutie nebolo nikdy tak jednoduché.'},
    { gender: 'woman', name: 'Renata', subtitle: '1 year of training with Honza', text: 'It is hard and painful if You want to know and be able to do more than u can imagine now. Your will gets cool and You have a faster, stronger and lighter body. You modify it as You train. Your food gets more tasty with all delicious smoothies and rich nutrition meals. You can rely on Yourself more and You miss a lot if u skip a training.' },
    { gender: 'man', name: 'Libor', subtitle: null, text: 'Hraji celý život volejbal a mám po operaci ramene, takže zajít si do fitka bez odborníka nebylo moc možné. Milým překvapením bylo, když se z Honzy stal trenér a vzal mě pod svá křídla. K odbornosti bych do výčtu důvodů proč spolupracovat s Honzou, mohl přidat lidskost, otevřenost i trpělivost.' },
    { gender: 'woman', name: 'Aidy', subtitle: null, text: 'Why would I recommend Jan. First of all he is a good example of workout+nutrition done right, he makes us all feel like we can achieve better shape with better habits. I personally like that he has good theoretical knowledge which he can back up with his pecs 😀 and he is never late, he changes exercise almost every time, thus every new training is like a trip to muscle chocolate factory. He has good knowledge of english and good trainer mindset - training does not feel too serious/militaristic but still not too liberal to start slacking off. Overalls i will recommend him a lot, as long as he keeps our training spots reserved for us. 🙂' },
];

const genderImages = {
    man: '/images/icons/man.png',
    woman: '/images/icons/woman.png',
};

const philosophyImages = [
    { title: 'Strava', image: '/images/icons/plate.png' },
    { title: 'Pohyb', image: '/images/icons/dumbbell.png' },
    { title: 'Odpočinek', image: '/images/icons/bed.png' },
];

const services = [
    { id: 1, title: 'Osobní tréninky', image: '/images/personal_training.jpg', text: (<React.Fragment><p>Naučíme se správné techniky cviků a jak strukturovat tréninkovou jednotku, aby bylo cvičení dlouhodobě efektivní.</p><p>Tréninky probíhají v&nbsp;Brně v <StyledLink variant="link" target="_blank" href="https://www.newpark.cz/">New Park Gym</StyledLink>. Možno trénovat i&nbsp;ve dvojici.</p></React.Fragment>), price: (<React.Fragment>600 Kč / trénink<br />800 Kč / trénink ve dvojici</React.Fragment>), action: 'Chci trénovat!' },
    { id: 2, title: 'Sestavení jídelníčku na míru', image: '/images/custom_diet.jpg', text: (<React.Fragment><p>Na základě současného způsobu stravování a životního stylu připravím doporučený stravovací plán. Vše ti dobře vysvětlím a první měsíc ti budu k&nbsp;dispozici na konzultace a případné úpravy jídelníčku.</p><p>Cílem je dlouhodobé nastavení stravovacích návyků, nikoli přehnaně striktní dodržování předepsaného plánu.</p></React.Fragment>), price: (<React.Fragment>2490 Kč</React.Fragment>), action: 'Chci se správně stravovat!' },
    { id: 3, title: 'Balíček služeb na míru', image: '/images/custom_bundle.jpg', text: (<React.Fragment><p>Individuální nastavení kombinace služeb. Ideální pro ty, kteří se do toho chtějí pořádně obout a začít na sobě pracovat po všech stránkách.</p><p>Společně nastavíme frekvenci a strukturu cvičení, zapracujeme na stravovacích návycích a pravidelně budeme kontrolovat progres.</p></React.Fragment>), price: null, action: 'Jdu do toho!' },
    { id: 4, title: 'Sestavení tréninkového plánu', image: '/images/training_plan.jpg', text: (<React.Fragment><p>Zvládáš správné techniky cviků, ale nedaří se ti dosáhnout progresu či chceš jednoduše maximalizovat efektivitu tréninku? Plán je sestaven individuálně dle tvých cílů (redukce váhy, zvýšení síly, přibírání svalové hmoty) a časových možností. Obsahuje periodizaci cviků a zátěže na 8-12 týdnů. Během této doby jsme spolu v&nbsp;kontaktu a plán případně upravujeme pro maximální efektivitu.</p></React.Fragment>), price: (<React.Fragment>1490 Kč</React.Fragment>), action: 'Chci začít pořádně makat!' },
];

const extendedFormServiceId = 4;

const orderFormResults = {
    success: { type: 'success', title: 'Úspěšně odesláno', message: 'Díky, brzy se ti ozvu a pustíme se do toho!' },
    failure: { type: 'failure', title: 'Nepodařilo se odeslat', message: 'Něco se nepovedlo, kontaktujte mě prosím přímo emailem nebo telefonem.' },
};

const contacts = [
    { icon: 'user', title: 'Jméno', value: (<React.Fragment>Ing. Jan Klečka<br />IČO: 06365582</React.Fragment>) },
    { icon: 'envelope', title: 'Email', value: 'honza@simple-fitness.cz' },
    { icon: 'mobile', title: 'Telefon', value: '+420 601 151 277' },
];

const socials = [
    { icon: 'facebook', title: 'Facebook', link: 'https://www.facebook.com/jankleckafitness/' },
    { icon: 'instagram', title: 'Instagram', link: 'https://www.instagram.com/jk_simple_fitness/' },
];

const partners = [
    { title: 'NewPark Gym', image: '/images/partners/new_park_gym.png', link: 'https://www.newpark.cz/' },
    { title: 'Chevron Nutrition', image: '/images/partners/chevron_nutrition.png', link: 'https://www.chevronnutrition.cz/' },
];

export {
    sections,
    logo,
    imageIntro,
    imageOutro,
    imageAbout,
    reviews,
    genderImages,
    philosophyImages,
    services,
    extendedFormServiceId,
    orderFormResults,
    contacts,
    socials,
    partners,
};
