import React from 'react';
import { Spinner } from 'react-bootstrap';

import StyledLoadingButton from './StyledLoadingButton';

function LoadingButton(props) {
    return (
        <StyledLoadingButton onClick={props.onClick} disabled={props.isLoading}>
            <span className="button-label">
                {props.isLoading ? (
                    <Spinner as="span" animation="border" role="status">
                        <span className="sr-only">{props.labelLoading}</span>
                    </Spinner>
                ) : props.label}
            </span>
        </StyledLoadingButton>
    );
};

export default LoadingButton;
