import styled from 'styled-components';

const StyledImageSection = styled.section`
    margin-top: 56px;

    width: 100%;
    height: 60vh;
    min-height: 35rem;
    padding: 15rem 0;

    position: relative;

    background-color: ${props => props.theme.colors.dark};
    
    div.content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        
        width: 100%;
        height: 60vh;
        min-height: 35rem;
        padding: 15rem 0;
        
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        
        background-image: linear-gradient(
            ${props => props.theme.gradient(props.theme.colorsRGB.dark, 0.1)} 75%,
            ${props => props.theme.gradient(props.theme.colorsRGB.dark, 1)} 100%
        ), url(${props => props.image});
    }
    
    @media (min-width: 768px) {
        height: 80vh;
    
        div.content-wrapper {
            height: 80vh;
            background-position: center top;
        }
    }
    
    @media (min-width: 992px) {
        &, div.content-wrapper {
            height: 100vh;
        }
    }
`;

export default StyledImageSection;
