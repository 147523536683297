import React from 'react';
import { Modal } from 'react-bootstrap';

import DialogContext from '../../DialogContext';
import OrderForm from './OrderForm';

function ContactFormResultDialog(props) {
    return (
        <DialogContext.Consumer>
            {({ ofDialogShow, ofDialogSetShow, ofs }) => (
                <Modal show={ofDialogShow} onHide={() => ofDialogSetShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ofs.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <OrderForm services={props.services} orderFormResults={props.orderFormResults} extendedFormServiceId={props.extendedFormServiceId} service={ofs} />
                    </Modal.Body>
                </Modal>
            )}
        </DialogContext.Consumer>
    );
};

export default ContactFormResultDialog;
