import styled from 'styled-components';

const StyledIntro = styled.div`
    color: ${props => props.theme.colors.light};
    
    text-align: center;
    margin-top: -2rem;
    
    h1 {
        font-family: ${props => props.theme.fonts.accent.family};
        font-weight: ${props => props.theme.fonts.accent.weight};
    
        display: inline-block;
        line-height: 0.7;
        
        margin: 0;
        word-break: break-word;
        white-space: normal;
        
        opacity: 0.7;
        
        font-size: 3.85rem;
        width: 344px;
    }
    
    h1 strong {
        font-size: 4.375rem;
    }
    
    div {
      opacity: 0.8;
      
      margin-top: 0.2rem;
    }
    
    p {
      margin: 0;
      padding: 0;
      
      font-size: 1.1rem;
      line-height: 1.4rem;
    }
    
    @media (min-width: 768px) {
        h1 {
            font-size: 7rem;
            width: 602px;
        }
        
        h1 strong {
            font-size: 8.025rem;
        }
        
        div {
            margin-top: 0.5rem;
        }
        
        p {
            font-size: 1.5rem;
            line-height: 1.8rem;
        }
    }
    
    @media (min-width: 768px) {
        margin-top: 2rem;
    }
    
    @media (min-width: 992px) {
        margin-top: 6rem;
    }
`;

export default StyledIntro;
