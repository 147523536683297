import React from 'react';

import StyledButton from './StyledButton';

function Button(props) {
    return (
        <StyledButton className={props.className} size="lg" href={props.href} onClick={props.onClick} disabled={props.disabled}>{props.children}</StyledButton>
    );
};

export default Button;
