import React from 'react';
import { Container, Image } from 'react-bootstrap';

import StyledPhilosophySection from './StyledPhilosophySection';
import Button from '../content/Button';

const onClick = (event) => {
    event.preventDefault();
    event.target.blur();

    Array.prototype.forEach.call(document.getElementsByClassName('nav-link'), i => {
        if (i.getAttribute('href') === '#services') {
            i.click();
        }
    });
};

function PhilosophySection(props) {
    return (
        <StyledPhilosophySection id="philosophy">
            <Container>
                <h2><strong>Filosofie</strong> Simple Fitness</h2>
                <p>Za léta cvičení jsem si vybudoval soubor určitých pravidel a zvyků, díky kterým se mi daří dosahovat požadovaného progresu a zároveň mi dávají i&nbsp;potřebnou míru volnosti v&nbsp;běžném životě. Není nutné svazovat se striktními stravovacími plány, podle kterých většina z&nbsp;nás nevydrží jíst celý měsíc, natož pak po celý rok. Důležité je, vybudování správných návyků, díky kterým nebudeme muset na jaře řešit shazování do plavek ale budeme mít svou váhu pod kontrolou celoročně.</p>
                <p>Cílem Simple Fitness je pomoci vám najít svou vlastní rovnováhu mezi třemi základními faktory - <strong>strava</strong>, <strong>pohyb</strong>, <strong>odpočinek</strong> a tím dosáhnout dlouhodobých výsledků.</p>

                <div className="mt-5 mb-5 d-flex flex-wrap justify-content-around">
                    {props.images.map((i, ix) => (
                        <div key={ix} className="mb-3">
                            <Image src={i.image} alt={i.title} className="mb-1" />
                            <figcaption className="text-center font-weight-bold">{i.title}</figcaption>
                        </div>
                    ))}
                </div>

                <p>Nejedná se o&nbsp;žádnou zázračnou metodu, pouze o&nbsp;dodržování jednoduchých zásad a nastavení dlouhodobě udržitelného přístupu.</p>
                <br />
                <h3>Simple Fitness v&nbsp;kostce</h3>
                <ul>
                    <li>cílem je dlouhodobě udržitelná změna (nikoli striktní ale krátkodobé vybočení ze stereotypu)</li>
                    <li>držíme se jednoduchých a fungujících principů (nebazírujeme na maličkostech)</li>
                    <li>k fitness přistupujeme komplexně, věnujeme pozornost všem 3 základním aspektům (pohyb, jídlo, odpočinek)</li>
                    <li>dbáme na pestrost a individualitu, musí vás to bavit</li>
                </ul>
                <br />

                <h3>Proč se do toho pustit?</h3>
                <p>Nejde jen o&nbsp;růst svalů nebo shazování tuku. Pohybem, vyváženou stravou a dostatkem odpočinku přímo ovlivňujeme kvalitu našeho života a dosahujeme:</p>
                <ul>
                    <li>lepšího spánku</li>
                    <li>více energie do práce i&nbsp;do života</li>
                    <li>méně stresu</li>
                    <li>lepší psychická vyrovnanost</li>
                    <li>zbavování se a předcházení bolesti zad</li>
                    <li>méně depresí</li>
                </ul>
                <br />

                <h4>Nebaví mě cvičit v&nbsp;posilovně</h4>
                <p>Těžké činky a běhací pásy s výhledem do zdi nechytnou každého, to je v&nbsp;pořádku. Neměla by to ale být výmluva nemít žádný pohyb. Sportovat můžeme všelijak a je jen na vás, co si vyberete a co vás baví.</p>
                <br />

                <div className="text-center mt-4 mb-5">
                    <Button onClick={onClick}>Chci se do toho pustit!</Button>
                </div>
            </Container>
        </StyledPhilosophySection>
    );
};

export default PhilosophySection;
