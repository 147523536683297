import styled from 'styled-components';

import { Button } from 'react-bootstrap';

const StyledPartner = styled(Button)`
    background-color: ${props => props.theme.colors.dark2};
    
    img {
        height: 50px;
    }
`;

export default StyledPartner;
