import React from 'react';
import { Container, Row } from "react-bootstrap";

import StyledContactSection from './StyledContactSection';
import Contact from '../content/Contact';
import Social from '../content/Social';
import Partner from '../content/Partner';

function ContactSection(props) {
    return (
        <StyledContactSection id="contact">
            <Container>
                <Row className="mt-5">
                    {props.contacts.map((i, ix) => <Contact key={ix} {...i} />)}
                </Row>
                <div className="d-flex justify-content-center my-5">
                    {props.socials.map((i, ix) => <Social key={ix} {...i} />)}
                </div>
                <div className="d-flex flex-wrap justify-content-center my-5">
                    {props.partners.map((i, ix) => <Partner key={ix} {...i} />)}
                </div>
                <footer className="text-center">
                    © {(new Date()).getFullYear()} LucaVers Design, grafický návrh a fotografie Lukáš Juračka, zhotovil Lukáš Frencl
                </footer>
            </Container>
        </StyledContactSection>
    );
};

export default ContactSection;
