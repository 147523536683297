import React from 'react';

import ImageSection from './ImageSection';
import Intro from '../content/Intro';

function IntroSection(props) {
    return (
        <ImageSection image={props.image}>
            <Intro title={props.title} />
        </ImageSection>
    );
};

export default IntroSection;
