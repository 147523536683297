import styled from 'styled-components';

const StyledReviewsSection = styled.section`
    padding-top: 3rem;
    padding-bottom: 2rem;
    
    color: ${props => props.theme.colors.dark2};
    
    h2 {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 4rem;
        
        color: ${props => props.theme.colors.dark};
    }
`;

export default StyledReviewsSection;
