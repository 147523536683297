import styled from 'styled-components';

const StyledServicesSection = styled.section`
    padding-top: 4rem;
    padding-bottom: 2rem;
    
    color: ${props => props.theme.colors.light};
    background-color: ${props => props.theme.colors.dark};
    
    p {
        text-align: justify;
    }
`;

export default StyledServicesSection;
