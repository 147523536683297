import React from 'react';
import { Modal } from 'react-bootstrap';

import DialogContext from '../../DialogContext';

import Button from './Button';

function GdprDialog(props) {
    return (
        <DialogContext.Consumer>
            {({ gdprDialogShow, gdprDialogSetShow }) => (
                <Modal show={gdprDialogShow} onHide={() => gdprDialogSetShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Zásady zpracování osobních údajů</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6 class="text-center"><b>I.<br />Základní ustanovení</b></h6>
                        <ol>
                            <li>Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen: "<b>GDPR</b>") je Jan Klečka IČ 06365582 se sídlem Akátová 3474, 738 01 Frýdek-Místek (dále jen: "<b>správce</b>").</li>
                            <li>Kontaktní údaje správce jsou:
                                <ul>
                                    <li>adresa: Akátová 3474, 738 01 Frýdek-Místek</li>
                                    <li>e-mail: honza@simple-fitness.cz</li>
                                    <li>telefon: +420 601 151 277</li>
                                </ul>
                            </li>
                            <li>Osobním údajem se ve smyslu nařízení Evropského parlamentu a Rady (EU) 2016/6792016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen "GDPR") rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě</li>
                        </ol>
                        <h6 class="text-center"><b>II.<br />Zdroje a kategorie zpracovávaných osobních údajů</b></h6>
                        <ol>
                            <li>Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce získal na základě plnění Vaší objednávky.</li>
                            <li>Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění smlouvy.</li>
                        </ol>
                        <h6 class="text-center"><b>III.<br />Zákonný důvod a účel zpracování osobních údajů</b></h6>
                        <ol>
                            <li>Zákonným důvodem zpracování osobních údajů je
                                <ul>
                                    <li>plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,</li>
                                    <li>oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,</li>
                                    <li>Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti v případě, že nedošlo k objednávce zboží nebo služby.</li>
                                </ul>
                            </li>
                            <li>Účelem zpracování osobních údajů je
                                <ul>
                                    <li>vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze strany správce plnit,</li>
                                    <li>zasílání obchodních sdělení a činění dalších marketingových aktivit.</li>
                                </ul>
                            </li>
                            <li>Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste poskytl/a svůj výslovný souhlas.</li>
                        </ol>
                        <h6 class="text-center"><b>IV.<br />Doba uchovávání údajů</b></h6>
                        <ol>
                            <li>Správce uchovává osobní údaje
                                <ul>
                                    <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a správcem a uplatňování nároků z těchto smluvních vztahů</li>
                                    <li>po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu, nejdéle 5 let, jsou-li osobní údaje zpracovávány na základě souhlasu.</li>
                                </ul>
                            </li>
                            <li>Po uplynutí doby uchovávání osobních údajů správce požádá o opětovné udělení souhlasu a v případě neúspěchu osobní údaje vymaže.</li>
                        </ol>
                        <h6 class="text-center"><b>V.<br />Příjemci osobních údajů (subdodavatelé správce)</b></h6>
                        <ol>
                            <li>Příjemci osobních údajů jsou osoby:
                                <ul>
                                    <li>podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,</li>
                                    <li>zajišťující služby provozování webové prezentace a další služby v souvislosti s provozováním webové prezentace,</li>
                                    <li>zajišťující marketingové služby.</li>
                                </ul>
                            </li>
                            <li>Správce  nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.</li>
                        </ol>
                        <h6 class="text-center"><b>VI.<br />Vaše práva</b></h6>
                        <ol>
                            <li>Za podmínek stanovených v GDPR máte:
                                <ul>
                                    <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</li>
                                    <li>právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18 GDPR,</li>
                                    <li>právo na výmaz osobních údajů dle čl. 17 GDPR,</li>
                                    <li>právo vznést námitku proti zpracování dle čl. 21 GDPR,</li>
                                    <li>právo na přenositelnost údajů dle čl. 20 GDPR a</li>
                                    <li>právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email správce uvedený v čl. I. odst. 2 těchto podmínek.</li>
                                </ul>
                            </li>
                            <li>Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.</li>
                        </ol>
                        <h6 class="text-center"><b>VII.<br />Podmínky zabezpečení osobních údajů</b></h6>
                        <ol>
                            <li>Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
                            <li>Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů v listinné podobě.</li>
                            <li>Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.</li>
                        </ol>
                        <h6 class="text-center"><b>VIII.<br />Závěrečná ustanovení</b></h6>
                        <ol>
                            <li>Odesláním rezervace z internetového rezervačního formuláře potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
                            <li>S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.</li>
                            <li>Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek na e-mailovou adresu, kterou jste správci poskytl/a.</li>
                        </ol>
                        Tyto podmínky nabývají účinnosti dnem 25. 5. 2021.
                    </Modal.Body>

                    <Modal.Footer className="justify-content-center">
                        <Button onClick={() => gdprDialogSetShow(false)}>Zavřít</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </DialogContext.Consumer>
    );
};

export default GdprDialog;
