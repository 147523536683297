import React from 'react';
import { ThemeProvider } from 'styled-components';

import NavBar from './components/navigation/NavBar';
import IntroSection from './components/sections/IntroSection';
import ImageSection from './components/sections/ImageSection';
import AboutSection from './components/sections/AboutSection';
import PhilosophySection from './components/sections/PhilosophySection';
import ServicesSection from './components/sections/ServicesSection';
import ReviewsSection from './components/sections/ReviewsSection';
import ContactSection from './components/sections/ContactSection';

import theme from './theme';
import {
    sections, logo, imageIntro, imageOutro, imageAbout, reviews, genderImages, philosophyImages,
    services, extendedFormServiceId, orderFormResults,
    contacts, socials, partners,
} from './configuration';

import GlobalStyle from './GlobalStyle';
import DialogContextProvider from './DialogContextProvider';

import GdprDialog from './components/content/GdprDialog';
import OrderFormDialog from './components/content/OrderFormDialog';
import OrderFormResultDialog from './components/content/OrderFormResultDialog';

function App() {
    React.useEffect(() => {
        document.dispatchEvent(new CustomEvent('AppLoaded'));
    }, []);

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <DialogContextProvider>
                    <NavBar sections={sections} logo={logo} />
                    <IntroSection image={imageIntro} title={logo.title} />
                    <AboutSection image={imageAbout} />
                    <PhilosophySection images={philosophyImages} />
                    <ServicesSection services={services} />
                    <ReviewsSection reviews={reviews} genderImages={genderImages} />
                    <ImageSection image={imageOutro} />
                    <ContactSection contacts={contacts} socials={socials} partners={partners} />
                    <GdprDialog />
                    <OrderFormDialog services={services} orderFormResults={orderFormResults} extendedFormServiceId={extendedFormServiceId} />
                    <OrderFormResultDialog orderFormResults={orderFormResults} />
                </DialogContextProvider>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default App;
