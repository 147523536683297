import styled from 'styled-components';

import Button from './Button';

const StyledLoadingButton = styled(Button)`
    .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
    }
    .button-label {
        display: inline-block;
        min-width: 70px;
    }
`;

export default StyledLoadingButton;
