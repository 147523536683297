import React from 'react';

import StyledImageSection from './StyledImageSection';

function ImageSection(props) {
    return (
        <StyledImageSection image={props.image}>
            <div className="content-wrapper">
                {props.children}
            </div>
        </StyledImageSection>
    );
};

export default ImageSection;
