import React from 'react';

import { Card } from "react-bootstrap";

import StyledContact from './StyledContact';

function Contact(props) {
    return (
        <StyledContact md={4} className="mb-3 mb-md-0">
            <Card className="py-4 h-100">
                <Card.Body className="text-center">
                    <i className={`fa fa-${props.icon}`} />
                    <div className="text-uppercase m-0">{props.title}</div>
                    <hr className="my-2" />
                    <div>{props.value}</div>
                </Card.Body>
            </Card>
        </StyledContact>
    );
};

export default Contact;
