import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import DialogContext from '../../DialogContext';

import StyledServicesSection from './StyledServicesSection';
import Button from '../content/Button';

/*const onClick = (event) => {
    event.preventDefault();
    event.target.blur();

    Array.prototype.forEach.call(document.getElementsByClassName('nav-link'), i => {
        if (i.getAttribute('href') === '#contact') {
            i.click();
        }
    });
};*/

const colHeading = (i, ix) => (
    <Col lg={6} className={`mb-5 order-lg-${ix % 2 === 0 ? 1 : 12}`}>
        <Image src={i.image} className="img-fluid" />
    </Col>
);

const colContent = (i, ix) => (
    <Col lg={6} className={`order-lg-${ix % 2 === 1 ? 1 : 12}`}>
        <h3 className="mb-3">{i.title}</h3>
        <div className="service-text">
            {i.text}
        </div>
        {i.price !== null && (
            <div>
                <div className="d-inline-flex mr-2">Cena:</div>
                <div className="d-inline-flex">{i.price}</div>
            </div>
        )}
        <div className="mt-4 mb-5">
            <DialogContext.Consumer>
                {({ ofsSet, ofDialogSetShow }) => (
                    <React.Fragment>
                        <Button onClick={(event) => { event.preventDefault(); event.target.blur(); ofsSet(i); ofDialogSetShow(true) }}>{i.action}</Button>
                    </React.Fragment>
                )}
            </DialogContext.Consumer>
        </div>
    </Col>
);

function ServicesSection(props) {
    return (
        <StyledServicesSection id="services">
            <Container>
                {props.services.map((i, ix) => (
                    <Row key={i.id}>
                        {/*ix % 2 === 1 && colContent(i, ix)*/}
                        {colHeading(i, ix)}
                        {/*ix % 2 === 0 && */colContent(i, ix)}
                    </Row>
                ))}
            </Container>
        </StyledServicesSection>
    );
};

export default ServicesSection;
