import styled from 'styled-components';

import { Button } from "react-bootstrap";

const StyledLink = styled(Button)`
    color: ${props => props.theme.colors.accent};

    .disabled, :disabled {
        color: ${props => props.theme.colors.accent};
    }
    
    &:hover, &:focus, &&&:active {
        color: ${props => props.theme.colors.accent2};
    }
    
    &:hover {
        cursor: pointer;
    }
`;

export default StyledLink;
