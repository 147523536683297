import styled from 'styled-components';

const StyledContactSection = styled.section`
    padding-top: 3rem;
    padding-bottom: 1rem;
    
    color: ${props => props.theme.colors.light};
    background: ${props => props.theme.colors.dark} linear-gradient(
        to bottom,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 1)} 0%,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 0.9)} 75%,
        ${props => props.theme.gradient(props.theme.colorsRGB.dark, 0.8)} 100%);
    
    h2 {
        margin-bottom: 2rem;
        text-align: center;
    }

    @media (max-width: 992px) {
        .container {
            max-width: 920px;
        }
    }
`;

export default StyledContactSection;
