import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';

import StyledReview from './StyledReview';

function Review(props) {
    return (
        <StyledReview>
            <Card className="mb-3">
                <Row noGutters={true}>
                    <Col md={3} lg={2} className="text-center">
                        <Image src={props.genderImages[props.gender]} fluid />
                    </Col>
                    <Col md={9} lg={10}>
                        <Card.Body>
                            <Card.Text>{props.text}</Card.Text>
                            <Card.Text>
                                <small className="text-muted">{props.name}
                                    {props.subtitle !== null ? <React.Fragment>, <em>{props.subtitle}</em></React.Fragment> : null}
                                </small>
                            </Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </StyledReview>
    );
};

export default Review;
