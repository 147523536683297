import React from 'react';
import { Container } from "react-bootstrap";

import StyledReviewsSection from './StyledReviewsSection';

import Review from '../content/Review';

function ReviewsSection(props) {
    return (
        <StyledReviewsSection id="reviews">
            <Container>
                <h2>Co říkají klienti</h2>
                {props.reviews.map((r, i) => <Review key={i} genderImages={props.genderImages} {...r} />)}
            </Container>
        </StyledReviewsSection>
    );
};

export default ReviewsSection;
