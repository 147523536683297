import styled from 'styled-components';

import { Button } from 'react-bootstrap';

const StyledButton = styled(Button)`
    color: ${props => props.theme.colors.light2};
    background-color: ${props => props.theme.colors.accent};
    border-color: ${props => props.theme.colors.accent};
    
    .disabled, :disabled {
        color: ${props => props.theme.colors.light2};
        background-color: ${props => props.theme.colors.accent};
        border-color: ${props => props.theme.colors.accent};
    }
    
    &:hover, &:focus, &&&:active {
        color: ${props => props.theme.colors.light2};
        background-color: ${props => props.theme.colors.accent2};
        border-color: ${props => props.theme.colors.accent2};
    }
`;

export default StyledButton;
