const theme = {
    colors: {
        light: '#eff0f1',
        light2: 'white',
        dark: '#19150f',
        dark2: '#646464',
        accent: '#a10b10',
        accent2: '#6b0000',
    },
    fonts: {
        main: {
            family: 'Bahnschrift, sans-serif',
            weight: 300,
            style: 'normal',
        },
        accent: {
            family: 'aviano-future, sans-serif',
            weight: 900,
            style: 'normal',
        },
    },
    colorsRGB: {
        dark: [0, 0, 0],
    },
    gradient: (colorRGB, opacity) => {
        return 'rgba(' + colorRGB.concat(isFinite(opacity) ? opacity : 1).join(', ') + ')';
    },
};

const hexToRGB = (hex) => {
    return (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) });
};

theme.colorsRGB.dark = hexToRGB(theme.colors.dark);

export default theme;
