import styled from 'styled-components';
import { Form } from "react-bootstrap";

const StyledOrderForm = styled(Form)`
    {/*@media (min-width: 768px) {
        width: 90%;
    }
    
    @media (min-width: 1200px) {
        width: 60%;
    }*/}
    margin-top: 1rem;
    
    div.col {
        padding-bottom: 0.8rem;
    }
    
    .invalid-tooltip {
        top: -1.6rem;
        left: 1rem;
        line-height: 1;
    }
    
    .form-check .invalid-tooltip {
        display: inline-block;
        position: static;
        margin-left: 0.5rem;
    }
`;

export default StyledOrderForm;
