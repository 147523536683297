import styled from 'styled-components';

import Logo from './Logo';

const StyledLogo = styled(Logo)`
    &.navbar-brand {
        padding: 7px 0 3px 0;
    }
    
    img {
        width: 41px;
    }
    
    span {
        font-family: ${props => props.theme.fonts.accent.family};
        font-weight: ${props => props.theme.fonts.accent.weight};
    
        font-size: 1.6rem;
    
        color: #19150f;
    
        display: inline-block;
        line-height: 0.75;
    
        width: 174px;
        margin: 0;
        word-break: break-word;
        white-space: normal;
    }
    
    span strong {
        font-size: 1.84rem;
    }
`;

export default StyledLogo;
