import React from 'react';

const DialogContext = React.createContext({
    gdprDialogShow: false,
    gdprDialogSetShow: () => {},

    ofDialogShow: false,
    ofDialogSetShow: () => {},

    ofrDialogShow: false,
    ofrDialogSetShow: () => {},

    ofs: 0,
    ofsSet: () => {},

    ofr: 'success',
    ofrSet: () => {},
});

export default DialogContext;
